import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate } from 'react-router-dom';
import ErrorField from '../../components/ErrorField';
import appLogo from '../../assets/img/camatGo-2022-small.png'
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserPayload } from '../../api/user-api';
import { registerUser } from '../../api/common-api';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import Helper from '../../utils/Helper';

type Props = {};

const Register: React.FC<Props> = () => {
    const [loader, showLoader] = useState(false)
    const navigate = useNavigate()

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<UserPayload>({ criteriaMode: "all" });

    const onSubmitForm: SubmitHandler<UserPayload> = async ({ name, username, email, password, password_confirmation }) => {
        showLoader(true)
        const payload = {
            name, username, email, password, password_confirmation,
            device_name: "web",
            role: "2"
        }
        const res = await registerUser(payload)
        console.log(res)

        if (res.status === 200) {
            toast.success(res.data?.message)

            // const { token } = res.data.data
            // setTitleLoader("Loading configuration...")

            // const { data } = await getLoginInfo(token)
            // const resConfig = await getConfig('', token)
            // if(resConfig.status === 200){
            //     if(resConfig.data.success){
            //         localStorage.setItem('config', JSON.stringify(resConfig.data.data))
            //     }
            // }
            // localStorage.setItem('dataUser', JSON.stringify(data))
            // localStorage.setItem('authToken', token)

            // navigate('/root', {replace: true})

            setTimeout(() => {
                navigate('/auth', { replace: true })
            }, 2000);
        } else {
            showLoader(false)
            Helper.bulkResponseError(res)
        }
    }


    return (
        <>
            <div className="h-full flex flex-col justify-center items-center space-y-6 overflow-y-auto py-10">

                <LazyLoadImage effect='blur' src={appLogo} className='w-20 h-20 mx-auto' />

                <div className='text-center'>
                    <h1 className="text-2xl md:text-3xl font-extrabold">Register your account</h1>
                    <p className="mt-5">Electronic System Administration Pajak</p>
                </div>

                <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-2.5 px-2 md:px-0 w-11/12 md:w-7/12">
                    <div>
                        <label className="font-semibold" htmlFor="name">Fullname</label>
                        <input id="name" type="name" autoComplete="name" className="text-input"
                            {...register("name", { required: "Fullname is required." })} />
                        <ErrorField errors={errors} name='name' />
                    </div>
                    <div>
                        <label className="font-semibold" htmlFor="username">Username</label>
                        <input id="username" type="username" autoComplete="username" className="text-input"
                            {...register("username", { required: "Username is required." })} />
                        <ErrorField errors={errors} name='username' />
                    </div>
                    <div>
                        <label className="font-semibold" htmlFor="email-address">Email address</label>
                        <input id="email-address" type="text" autoComplete="email" className="text-input"
                            {...register("email", {
                                required: "Email is required.",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address."
                                }
                            })} />
                        <ErrorField errors={errors} name='email' />
                    </div>
                    <div>
                        <label className="font-semibold" htmlFor="password">Password</label>
                        <input id="password" type="password" autoComplete="password" className="text-input"
                            {...register("password", {
                                required: "Password is required.",
                                minLength: { value: 5, message: "Password must exceed 4 characters." }
                            })} />
                        <ErrorField errors={errors} name='password' />
                    </div>
                    <div className='pb-3'>
                        <label className="font-semibold" htmlFor="password_confirmation">Password (Confirm)</label>
                        <input id="password_confirmation" type="password" autoComplete="password_confirmation" className="text-input"
                            {...register("password_confirmation", {
                                required: "Confirm Password is required.",
                                minLength: { value: 5, message: "Confirm Password must exceed 4 characters." }
                            })} />
                        <ErrorField errors={errors} name='password_confirmation' />
                    </div>

                    <button type='submit' className="btn-submit" >Sign Up</button>

                </form>

                <div className='font-medium text-sm flex items-center justify-center'>
                    <p>Already have an account?</p>
                    <Link to='/auth' className='text-primary hover:text-orange-500 ml-1'>Sign in</Link>
                </div>

            </div>

            {loader && <Loader />}
        </>
    );
}

export default Register;